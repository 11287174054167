<template>
  <main class="main_v8 help">
    <h2>Submit a support request to Wave7</h2>
    <my-editor v-model="this.letter" />
<!--    <editor v-model="this.letter" @paste="tmcePaste"-->
<!--      api-key="no-api-key"-->
<!--      :init="{-->
<!--        menubar: false,-->
<!--        plugins: 'lists link image imagetools code codesample nonbreaking',-->
<!--        toolbar: 'undo redo | h1 h2 h3 | bold underline italic | alignleft aligncenter alignright | bullist numlist codesample | image',-->
<!--        object_resizing: 'img',-->
<!--        branding: false,-->
<!--        file_picker_types: 'file image media',-->
<!--        content_style: `@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800'); body { font-family: Inter; };`,-->
<!--        image_uploadtab: true,-->
<!--        // images_upload_url: 'postAcceptor.php',-->
<!--        image_advtab: true,-->
<!--        imagetools_cors_hosts: ['localhost:8080'],-->
<!--        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',-->
<!--        content_css: '/content.css',-->
<!--        importcss_append: true,-->
<!--      }"-->
<!--    />-->
    <div class="user_info">
      <span class="options_toggle">SUPPORT REQUEST</span>
      <!-- <div class="field">
        <p class="subheader">To</p>
        <span>Wave7 Support Team</span>
      </div> -->

      <div class="field">
        <p class="subheader">Your email</p>
        <input id="email" placeholder="email@gmail.com" :value="store.state.user.email" >
      </div>
      <!-- <div class="field" v-for="(email, index) in emails" :key="index">
        <p v-if="index == 0" class="subheader">Your email</p>
        <p v-else class="subheader">Copy to</p>
        <input id="email" :placeholder="email" :value="email">
      </div>
      <div class="add_email" @click="addEmail">Add email</div> -->
      
      <div class="field">
        <p class="subheader">Subject</p>
        <input id="subject" :style="[err ? {'z-index': '4', 'background-color': '#fff'} : null]" v-model="subject" placeholder="Subject..." @keyup="iferr">
        <div class="message" :style="[err ? { 'z-index': '4', 'background-color': '#fff', 'display': 'block' } : null]"> Please make sure to add a subject. </div>
      </div>
      <div class="field">
        <p class="subheader">Severity</p>
        <select id="severity">
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>
      </div>

      <div class="attachments">
        <p class="subheader">Attachments</p>
        <div class="files">
          
          <div class="uploader">
            <DropZoneHelp id="doc" v-if="this.src == ''" :index="0" :height="150" :docid="this.docid" @drop.prevent="drop" @change="selectedFile" @uploadCompleted="uploadComplete" @uploadStarted='uploadStarted' />
          </div>

          <div class="doc" v-for="(doc, index) in $store.state.helpUploadFiles" :key="index" :class="doc[1]">
            <p>{{doc[0]}}</p>
            <span class="cross" @click="removeFiles(index)"></span>
          </div>

          <div class="add_img_label" @click="uploadHelp">+ ADD</div>
          
        </div>
      </div>
      <div class="actions">
        <btn class="secondary" v-text="texts[0]" @click.prevent="cancel"></btn>
        <btn v-text="texts[1]" @click="send"></btn>
      </div>
    </div>
    <transition name="fade">
      <div v-if="err" :style="[err ? {'backgroundColor': 'rgba(0, 0, 0, 0.7)'} : null]" class="shadow" @click="err = false"></div>
    </transition>
  </main>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { ref } from "vue";

import btn from '../components/buttons/button.vue';
import MyEditor from '@/components/editors/tiny_mce/editor.vue';
import {useStore} from "vuex";

const DropZoneHelp = defineAsyncComponent(
  () => import('../components/dropzones/cloudflare_attachments.vue'),
);
// import Editor from '@tinymce/tinymce-vue'
// import '../assets/js/tinymce/tinymce';
// // Theme
// import '../assets/js/tinymce/themes/silver/theme'
//
// // icons
// import '../assets/js/tinymce/icons/default'

// Skins
//import '../assets/js/tinymce/skins/ui/oxide/skin_help.css'
// import '../assets/js/tinymce/skins/ui/oxide/content.min.css'
//import '../assets/js/tinymce/skins/content/default/content.css'

export default {
  name: 'HelpViev',
  inject: ['apiPath'],
  components: {
    DropZoneHelp,
    btn,
    'editor': Editor,
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  data() {
    return {
      // desktop: false,
      texts: ['cancel', 'send'],
      src: '',
      letter: '',
      subject: '',
      err: false,
      emails: [
        this.$store.state.user.email,
        'email@email.com'
      ]
      ,
    };
  },
  watch: {
    letter(value) {
      localStorage.letter = value
    },
  },
  beforeCreate() {
    this.$store.state.helpUploadFiles = [];
  },
  created() {
    if (localStorage.letter) {
      this.letter = localStorage.letter
    }
  },
  methods: {
    iferr() {
      if (this.subject !== '') {
        this.err = false;
      } else {
        return;
      }
    },
    drop() {

    },
    selectedFile() {

    },
    addEmail() {
      this.emails.push('');
    },
    uploadHelp() {
      let fileUpload = document.getElementsByClassName('dropzone_files_contentedit');
      console.log(fileUpload);
      fileUpload[0].click();
    },
    uploadStarted(path,index) {
      this.uploading = true;
      let url = '';
      url = index;
      url = path;
      this.currentUrl = url;
      console.log(url)
    },
    uploadComplete(index) {
      console.log("INSIDE Help UploadComplete: index=",index," class=",this.class);
      //this.src = this.currentUrl;
      this.uploading = false;
      this.uploadEmpty = false;
    },
    cancel() { 
      localStorage.removeItem('letter');
      this.$router.go(-1);
    },
    send() {
      if (this.subject == "") {
        this.err = true;
        setTimeout(() => this.err = false, 3000);
      } else {
        this.$store.commit('savingPage');
        var formData = new FormData();
        formData.append('email', document.getElementById('email').value);
        formData.append('subject', this.subject);
        formData.append('severity', document.getElementById('severity').value);
        formData.append('content', "<div style='padding:10px;border:1px solid #555;'>" + this.letter + "</div>");
        formData.append('attachments', JSON.stringify(this.$store.state.helpUploadFiles));
        formData.append('token', this.$store.state.usertoken);
      
        fetch(`${this.apiPath}getHelp`, {
          method: 'POST',
          body: formData,
        }).then((res) => res.json()).then((response) => {
          if (response.error === undefined) {
            this.$store.commit('pageSaved');
          } else {
            if (response.error === 'Wrong token') {
              window.location.reload();
            }
          }
        }).catch((error) => {
          this.$store.commit('savingError');
          console.log('Looks like there was a problem with Help view: \n', error);
        });
        localStorage.removeItem('letter');
        setTimeout(
          () => {
            this.$router.go(-1);
          }, 2000
        );

      }
    },
    removeFiles(index) {
      this.$store.state.helpUploadFiles.splice(index,1);
    },
  },
  // beforeMount() {
  //   if (window.innerWidth > 927 && window.innerWidth > window.innerHeight) {
  //     console.log('desktop')
  //     this.desktop = true;
  //   } else {
  //     console.log('not desktop')
  //     this.desktop = false;
  //   }
  // },
  mounted() {

    // this.doesSessionExist();
  },
};
</script>

<style lang="scss" scoped>
*{
  -webkit-transition: all 0ms ease !important;
  transition: all 0ms ease!important;
}
.add_email{
  height: 36px;
  line-height: 36px;
  padding-left: 34px;
  background-image: url(../../public/img/libraryinfo/add_line.svg);
  background-repeat: no-repeat;
  background-position: center left;
  font-size: 1rem;
  color: #787878;
  display: inline-block;
  margin-bottom: 16px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  @media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 500px) and (orientation: landscape){
    margin-bottom: 0px;
    margin-top: 8px;
  }
}
.message{
  height: 75px;
  line-height: 75px;
  text-indent: 16px;
  border-radius: 16px;
  width: 340px;
  position: absolute;
  top: 45px;
  right: 0px;
  display: none;
  background-image: url(../../public/img/err.svg);
  background-repeat: no-repeat;
  background-position-x: 290px;
  background-position-y: center;
}
.message::before{
  content: '';
  display: block;
  height: 10px;
  width: 10px;
  position: absolute;
  top: -5px;
  right: 100px;
  background-color: #fff;
  transform: rotate(45deg);
}
.shadow{
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 3;
  transition: all .3s;
  background-color: rgba(0,0,0,0);
}
#subject{
  transition: all .3s;
}
.files{
  flex: 1;
  .doc{
    background-color: rgba(120, 120, 120, 0.05);
    display: inline-block;
    padding: 0px 30px 0px 40px;
    border-radius: 8px;
    box-sizing: border-box;
    margin-bottom: 8px;
    margin-right: 6px;
    width: 200px;
    background-image: url(../../public/img/book/doc.svg);
    background-repeat: no-repeat;
    background-position: 14px center;
    cursor: pointer;
    background-size: 10%;
    position: relative;
    height: 42px;
    overflow: hidden;
    p{
      margin: 0px;
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      height: 42px;
      line-height: 42px;
      color: #787878;
    }
    .cross{
      position: absolute;
      right: 0px;
      top: 0px;
      display: inline-block;
      background-image: url(../../public/img/gray_cross.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 14px;
      height: 42px;
      width: 30px;
      background-color: #f8f8f8;
    }
  }
  .pdf_img{
    background-image: url(../../public/img/book/pdf.svg);
  }
  .img_img{
    background-image: url(../../public/img/book/img.svg);
  }
}
.main_v8{
  h2{
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
  .user_info{
    position: absolute;
    // z-index: 1;
    max-width: 33%;
    width: 508px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: #fff;
    padding: 0 32px 25px;
    box-sizing: border-box;
    @media screen and (max-width: 1000px) and (min-width: 927px){
      padding: 32px 16px;
    }
    .options_toggle{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 48px;
      line-height: 16px;
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
      color: #787878;
      position: relative;
      margin-bottom: 16px;
    }
    .options_toggle::before,.options_toggle::after{
      content: '';
      width: calc(50% - 115px);
      height: 5px;
      position: absolute;
      background-color: rgba(37, 37, 37, 0.1);
      top: 22px;
      border-radius: 3px;
    }
    .options_toggle::before{
      left: 0px;
    }
    .options_toggle::after{
      right: 0px;
    }
  }
  .field{
    height: 40px;
    margin-bottom: 16px;
    position: relative;
    display: flex;
    .subheader{
      display: inline-block;
      margin: 0px;
      line-height: 40px;
      font-weight: 500;
      font-size: 1rem;
      width: 140px;
      color: #787878;
    }
    input{
      display: inline-block;
      margin: 0px;
      line-height: 40px;
      font-weight: 500;
      font-size: 1rem;
      width: 112px;
      color: #787878;
    }
    select{
      display: inline-block;
      margin: 0px;
      line-height: 40px;
      font-weight: 500;
      font-size: 1rem;
      width: 112px;
    }
    span{
      margin: 0;
      line-height: 40px;
      font-weight: 500;
      font-size: 1rem;
      color: #405FE8;
    }
    input:not([type="file"]),select{
      background-color: rgba(120, 120, 120, .05);
      border: 0px;
      height: 40px;
      border-radius: 8px;
      padding-left: 16px;
      box-sizing: border-box;
      flex: 1;
    }
    input:not([type="file"])::placeholder{
      color: #787878;
    }
    input[type="file"]{
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }
    select{
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url(../../public/img/arr_down.svg);
      background-repeat: no-repeat;
      background-position: right center;
      color: #787878;
      option{
        width: 200px;
        color: #787878;
      }
    }
    input:focus,input:active,select:focus,select:active{
      outline: 0px;
    }
  }
  .attachments{
    border-top: 1px solid #2525251A;
    padding-top: 10px;
    @media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 500px) and (orientation: landscape){
      margin-top: 16px;
    }
    .subheader{
      display: inline-block;
      margin: 0px;
      line-height: 40px;
      font-weight: 500;
      font-size: 1rem;
      width: 140px;
      color: #787878;
      margin-bottom: 10px;
    }
    .add_img_label{
      color: #787878;
      background-color: rgba(120, 120, 120, .05);
      font-size: 1rem;
      border-radius: 8px;
      height: 42px;
      line-height: 42px;
      padding: 0 20px;
      box-sizing: border-box;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
    }
    .uploader{
      position: absolute;
      height: 90px;
      width: 90px;
      left: -3px;
      top: 35px;
      transition: all .1s;
      .gaugePercent{
        font-size: 11px;
      }
    }
  }
  .description{
    display: none;
    color: #787878;
  }
  .actions{
    padding-top: 28px;
    border-top: 1px solid #2525251A;
    position: absolute;
    bottom: 32px;
    right: 32px;
    left: 32px;
    display: flex;
    .button{
      width: 50%;
      padding: 0;
    }
    .button:first-child{
      margin-right: 16px;
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px)
  and (min-height: 900px) and (orientation: portrait) {
    .main_v8{
      top: 52px;
      // @media screen and (min-width: 481px) and (max-width: 900px){
      //   top: 52px;
      // }
      h2{
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .field{
      display: flex;
      height: 32px;
      margin-bottom: 20px;
      input:not([type="file"]),select{
        height: 32px;
        width: 100%;
        position: relative;
        line-height: 32px;
      }
      p{
        height: 32px;
        line-height: 32px;
      }
    }
    .user_info{
      max-width: none;
      width: auto;
      left: 0px;
      top: auto;
      border-radius: 0;
      padding: 16px 32px 50px;
      .text_wrapper{
        height: 300px;
        margin-bottom: 16px;
        display: flex;
      }
      .description{
        display: inline-block;
        line-height: 40px;
        font-weight: 500;
        font-size: 14px;
        width: 112px;
        margin: 0px;
      }
      .options_toggle::before, .options_toggle::after{
        width: 225px;
      }
    }
    .actions{
      position: relative;
      justify-content: space-between;
      bottom: -30px;
      left: auto;
      right: 0px;
      .button{
        flex-basis: 49%;
      }
      .button:first-child{
        margin-right: 16px;
      }
    }
  }
  @media screen and (max-width: 1366px) and (min-width: 1024px) and (max-height: 1024px)
  and (min-height: 744px) and (orientation: landscape){
    .user_info{
      .options_toggle::before,.options_toggle::after{
        
      }
    }
    .main_v8{
      padding: 0 16px;
      h2{
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
  }
 @media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 500px) and (orientation: landscape){
  .user_info{
    .options_toggle{
      display: none;
    }
  }
  .main_v8{
    h2{
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
  .text_wrapper{
    height: 50vh;
  }
    .user_info{
      position: initial;
      max-width: none;
      background-color: rgba(256,256,256,.0);
      width: 100%;
      padding: 0px;
    }
    .form{
      margin-bottom: 0px;
    }
    .description{
      display: inline-block;
      margin: 0px;
      line-height: 40px;
      font-weight: 500;
      font-size: 14px;
      width: 112px;
    }
    .field{
      height: auto;
      margin-top: 8px;
      margin-bottom: 0px;
      input:not([type="file"]),select{
        position: initial;
        width: 100%;
        background-color: #fff;
      }
      p{
        display: block;
      }
      .cross{
        left: auto;
      }
      span{
        position: absolute;
        top: 0px;
        left: 30px;
      }
    }
    .doc{
      max-width: 185px;
    }
    .actions{
      display: block;
      position: relative;
      left: auto;
      right: auto;
      bottom: auto;
      margin: 16px 0;
      .button{
        width: 100%;
      }
      .button:first-child{
        margin-bottom: 16px;
      }
    }
  }
  @media screen and (width: 1024px) and (height: 768px), screen and (width: 1180px) and (height: 820px){
    .user_info{
      max-width: none;
      position: absolute;
      width: 400px;
      padding: 16px 16px;
    }
    .field{
      input:not([type="file"]),select{
        width: 250px;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s ease-out;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
